import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import api from '../../services/api';

export interface ModalCreateHandles {
  openModal: () => void;
}

interface PropsPage {
  loadUsers?: () => void;
}

const ModalUser: React.ForwardRefRenderFunction<ModalCreateHandles, PropsPage> = (props, ref) => {
  const [visible, setVisible] = useState(false);
  const formRefCreateUser = useRef<HTMLFormElement>(null);
  const MyTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  async function openModal() {
    setVisible(true);
  }

  async function handleCreateUser() {
    try {
      const inputs = formRefCreateUser.current?.elements;
      let data = {
        page: 'pages/modal/UserResetDataDialog',
        timezone: MyTimezone,
      };
      if (inputs) {
        for (let i = 0; i < inputs?.length; i += 1) {
          const valueInput = (inputs[i] as HTMLInputElement).value;
          const nameInput = (inputs[i] as HTMLInputElement).name;
          const tipoInput = (inputs[i] as HTMLInputElement).type;
          if (tipoInput === 'password' || tipoInput === 'text' || tipoInput === 'email') {
            data = { ...data, [nameInput]: valueInput };
          }
        }
      }

      await api.post('/users/', data);
      toast.success('User created');
      props.loadUsers && props.loadUsers();
      setVisible(false);
    } catch (error) {
      toast.error('Error create user');
    }
  }

  useImperativeHandle(ref, () => {
    return { openModal };
  });

  function handleCloseModal() {
    setVisible(false);
  }

  return (
    <Dialog open={visible}>
      <DialogTitle>User Create</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <form ref={formRefCreateUser}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField margin="dense" label="Enter name" fullWidth name="name" type="text" />
              </Grid>

              <Grid item xs={12}>
                <TextField margin="dense" label="Enter e-mail" fullWidth name="email" type="email" />
              </Grid>

              <Grid item xs={12}>
                <TextField margin="dense" label="Enter the new password" fullWidth name="password" type="password" />
              </Grid>

              <Grid item xs={12}>
                <TextField margin="dense" label="Confirm the new password" name="confirmation_password" type="password" fullWidth />
              </Grid>
            </Grid>

            <DialogActions>
              <Button color="secondary" onClick={() => handleCloseModal()}>
                Back
              </Button>
              <Button color="primary" onClick={() => handleCreateUser()}>
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(ModalUser);
