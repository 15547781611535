import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/Forward';
import DeleteIcon from '@material-ui/icons/Delete';

import Backdrop from '@material-ui/core/Backdrop';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';

import api from '../../services/api';
import EditUser, { DialogHandles } from './useEdit';
import ModalCreateUser, { ModalCreateHandles } from '../Modal/UserCreateDataDialog';
import { Box } from '@material-ui/core';

interface UserInterface {
  id: string;
  name: string;
  email: string;
  canceled: string;
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 99,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    width: '90%',
    marginBottom: 10,
  },
  container: {
    maxHeight: 440,
  },
}));

const UserSettings: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [AllUsers, setAllUsers] = useState<UserInterface[]>([]);
  const [UserEdit, setUserEdit] = useState<UserInterface | undefined>();
  const [UserDelete, setUserDelete] = useState<UserInterface | undefined>();
  const [openDeleteUser, setOpenDeleteUser] = React.useState(false);
  const dialogRef = useRef<DialogHandles>(null);
  const dialogCreateRef = useRef<ModalCreateHandles>(null);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (UserEdit) {
      dialogRef.current?.openDialog();
    }
  }, [UserEdit]);

  useEffect(() => {
    if (UserDelete) {
      setOpenDeleteUser(true);
    }
  }, [UserDelete]);

  async function loadUsers() {
    setLoading(true);

    try {
      const responseMachine = await api.get('/users');
      if (responseMachine.data && responseMachine.data.length > 0) {
        setAllUsers(responseMachine.data);
      }
    } catch (error) {
      toast.error(`Users not found: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteUser = async () => {
    try {
      await api.delete(`users/${UserDelete?.id}`);
      toast.success('User deleted');
      setOpenDeleteUser(false);
      setUserDelete(undefined);
      loadUsers();
    } catch (error) {
      toast.error('Error delete user');
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <LinearProgress className={classes.progress} color="secondary" />
        <Typography align="center">loading users</Typography>
      </Backdrop>
      <Dialog open={openDeleteUser} onClose={() => setOpenDeleteUser(false)}>
        <DialogTitle>Delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be undone</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDeleteUser(false)} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteUser} variant="contained" color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ModalCreateUser ref={dialogCreateRef} loadUsers={loadUsers} />
      <EditUser userEdit={UserEdit} ref={dialogRef} loadUsers={loadUsers} />
      <Button variant="contained" color="secondary" size="small" onClick={() => dialogCreateRef.current?.openModal()}>
        New
      </Button>
      <Box mt={1} />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AllUsers &&
              AllUsers.sort((eachUser, eachUser2) => (eachUser.name > eachUser2.name ? 1 : -1)).map((eachUser, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        setUserEdit({
                          id: eachUser.id,
                          name: eachUser.name,
                          email: eachUser.email,
                          canceled: eachUser.canceled,
                        })
                      }
                    >
                      <ForwardIcon color="secondary" />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        setUserDelete({
                          id: eachUser.id,
                          name: eachUser.name,
                          email: eachUser.email,
                          canceled: eachUser.canceled,
                        })
                      }
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{eachUser.id}</TableCell>
                  <TableCell>{eachUser.name}</TableCell>
                  <TableCell>{eachUser.email}</TableCell>
                  <TableCell>{eachUser.canceled === 'Y' ? 'yes' : 'no'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserSettings;
