import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Grid, InputAdornment, TextField, Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import BackdropComponent from '../../components/BackdropComponent';
import { Autocomplete } from '@material-ui/lab';
import { GithubPicker, ColorResult } from 'react-color';
import 'react-color-palette/lib/css/styles.css';
import { toast } from 'react-toastify';

import api from '../../services/api';
import { useAuth } from '../../hooks/AuthContext';

interface propsPage {
  open: boolean;
  handleClose: () => void;
  reloadMachines: () => void;
}

interface GroupsInterface {
  description: string;
  nivel: string;
}

interface FactoryInterface {
  description: string;
}

const MachineNew: React.FC<propsPage> = ({ open, handleClose, reloadMachines }) => {
  const [loading, setLoading] = useState(false);
  const formRefNewMachine = useRef<HTMLFormElement>(null);
  const [AllTables, setAllTables] = useState<string[]>([]);
  const [AllGroups, setAllGroups] = useState<string[]>([]);
  const [AllSubgroups, setAllSubgroups] = useState<string[]>([]);
  const [GroupSelected, setGroupSelected] = useState('');
  const [SubgroupSelected, setSubgroupSelected] = useState('');
  const [TableSelected, setTableSelected] = useState('');
  const [AllFactories, setAllFactories] = useState<string[]>([]);
  const [FactorySelected, setFactorySelected] = useState('');
  const [ColorPickerLow, setColorPickerLow] = useState('#DB3E00');
  const [ColorPickerAverage, setColorPickerAverage] = useState('#FCCB00');
  const [ColorPickerHigh, setColorPickerHigh] = useState('#008B02');
  const { user } = useAuth();
  const MyTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const colorsPiker = [
    '#f1eeee',
    '#B80000',
    '#DB3E00',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#ffffff',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#D4C4FB',
  ];

  async function handleSubmit() {
    try {
      setLoading(true);

      const inputs = formRefNewMachine.current?.elements;

      try {
        let data = {
          low_color: ColorPickerLow,
          high_color: ColorPickerHigh,
          average_color: ColorPickerAverage,
          machine: TableSelected,
          group: GroupSelected,
          subgroup: SubgroupSelected,
          factory: FactorySelected,
          user: user.name,
          user_id: user.id,
          timezone: MyTimezone,
        };
        if (inputs) {
          for (let i = 0; i < inputs?.length; i += 1) {
            const valueInput = (inputs[i] as HTMLInputElement).value;
            const nameInput = (inputs[i] as HTMLInputElement).name;
            const tipoInput = (inputs[i] as HTMLInputElement).type;

            if (tipoInput === 'text') {
              data = { ...data, [nameInput]: valueInput };
            }

            if (tipoInput === 'number') {
              // eslint-disable-next-line radix
              data = { ...data, [nameInput]: parseFloat(valueInput) };
            }
          }
        }
        await api.post('machine-settings', data);
        toast.success('Updated Success!');
        reloadMachines();
        handleClose();
      } catch (error: any) {
        throw new Error(error.message);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  async function loadDataMachine() {
    try {
      setLoading(true);
      setAllGroups([]);
      setAllFactories([]);
      setGroupSelected('');
      setSubgroupSelected('');
      setFactorySelected('');
      formRefNewMachine.current?.reset();

      const responseGroups = await api.get('/machine-groups');
      if (responseGroups.data && responseGroups.data.length > 0) {
        const groups = responseGroups.data
          .filter((eachGroup: GroupsInterface) => eachGroup.nivel === 'Group')
          .map((eachGroup: GroupsInterface) => eachGroup.description);
        const subgroups = responseGroups.data
          .filter((eachGroup: GroupsInterface) => eachGroup.nivel === 'Subgroup')
          .map((eachGroup: GroupsInterface) => eachGroup.description);
        setAllGroups(groups);
        setAllSubgroups(subgroups);
      }

      const responseFactory = await api.get('/factory');
      if (responseFactory.data && responseFactory.data.length > 0) {
        const factories = responseFactory.data.map((eachFactory: FactoryInterface) => eachFactory.description);
        setAllFactories(factories);
      }

      const responseTables = await api.get('/machine-settings/tables');
      if (responseTables.data && responseTables.data.length > 0) {
        const tables = responseTables.data.map((eachTable: { tablename: string }) => eachTable.tablename);
        setAllTables(tables);
      }
    } catch (error: any) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open === true) loadDataMachine();
  }, [open]);

  function handleChangeColorLow(color: ColorResult) {
    setColorPickerLow(color.hex);
  }
  function handleChangeColorAverage(color: ColorResult) {
    setColorPickerAverage(color.hex);
  }
  function handleChangeColorHigh(color: ColorResult) {
    setColorPickerHigh(color.hex);
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>New Machine</DialogTitle>
      <DialogContent>
        <BackdropComponent state={loading} message="Saving machine data" />
        <form ref={formRefNewMachine} autoComplete="off" className="classFormEditMachine">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={3}>
              <Autocomplete
                options={AllTables}
                value={TableSelected}
                getOptionLabel={option => option}
                onChange={(event, value) => setTableSelected(value || '')}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    label="Machine"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Autocomplete
                options={AllFactories}
                value={FactorySelected}
                getOptionLabel={option => option}
                onChange={(event, value) => setFactorySelected(value || '')}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    label="Factory"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Description" name="machine_description" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Autocomplete
                options={AllGroups}
                value={GroupSelected}
                getOptionLabel={option => option}
                onChange={(event, value) => setGroupSelected(value || '')}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    label="Group"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Autocomplete
                options={AllSubgroups}
                value={SubgroupSelected}
                getOptionLabel={option => option}
                onChange={(event, value) => setSubgroupSelected(value || '')}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    label="Subgroup"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Brand" name="brand" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Model" name="model" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Power source Manufacturer" name="power_source_manufacturer" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Power source Model" name="power_source_model" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Manufacturer" name="manufacturer" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Manufacturer torch" name="manufacturer_torch" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} type="number" label="Torch quantity" name="torch_quantity" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Type of Laser" name="type_laser" />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Table Length"
                name="table_length"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Table Width"
                name="table_width"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Mín. Thickness"
                type="number"
                name="min_tickness"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Máx. Tickness"
                name="max_tickness"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Usage Tickness"
                name="usage_tickness"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={2}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Power" name="power" />
            </Grid>
            <Grid item xs={12} sm={3} lg={2}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Grill Lifecycle" name="grill_lifecycle" />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Memo" name="memo" />
            </Grid>
            <Grid item xs={12} sm={4} lg>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="Low Efficiency Over" name="low_efficiency" type="number" />
              {ColorPickerLow !== '#ffffff' && <Box style={{ backgroundColor: ColorPickerLow, height: '20px' }} />}
              <Box mt={1} style={{ maxWidth: '237px' }} display="none">
                <GithubPicker colors={colorsPiker} width="100%" color={ColorPickerLow} onChange={handleChangeColorLow} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} lg>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Average Efficiency Over"
                name="average_efficiency"
                type="number"
              />
              {ColorPickerAverage !== '#ffffff' && <Box style={{ backgroundColor: ColorPickerAverage, height: '20px' }} />}
              <Box mt={1} style={{ maxWidth: '237px' }} display="none">
                <GithubPicker colors={colorsPiker} width="100%" color={ColorPickerAverage} onChange={handleChangeColorAverage} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} lg>
              <TextField fullWidth InputLabelProps={{ shrink: true }} label="High Efficiency Over" name="high_efficiency" type="number" />
              {ColorPickerHigh !== '#ffffff' && <Box style={{ backgroundColor: ColorPickerHigh, height: '20px' }} />}
              <Box mt={1} style={{ maxWidth: '237px' }} display="none">
                <GithubPicker colors={colorsPiker} width="100%" color={ColorPickerHigh} onChange={handleChangeColorHigh} />
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MachineNew;
